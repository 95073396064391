import React, { useState, useEffect } from "react";

import api from "services/api";

import { useApp } from "context/App";

import datePtBr from "helpers/datePtBr";

import {
  formatarCep,
  formatarCNPJ,
  formatarCPF,
  formatarMoeda,
  formatarPercentual,
  formatarStringData,
} from "helpers/Formatters";
import { telaVisualizada } from "helpers/telaVisualizada";

import "./styles.css";

export default function SolicitacaoPortabilidade(props) {
  const [dadosPortabilidade, setDadosPortabilidade] = useState(null);
  const { tokenSignatario, configuracaoAssinatura } = useApp();

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const urlIdExterno = props.idExterno ? `/${props.idExterno}` : "";

        const url = `Template/ObterDadosSolicitacaoPortabilidade/${tokenSignatario}${urlIdExterno}`;

        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "autorizacao"
            )}`,
          },
        });
        props.setIsLoading(false);
        props.setDadosValidos(true);
        setDadosPortabilidade(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };
    fecthData();
  }, [props.idDocumento, props.idExterno]);

  return (
    dadosPortabilidade && (
      <div className="container-solicitacao-portabilidade">
        <h2>Solicitação de Portabilidade</h2>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">1 - Dados do solicitante (devedor)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome</td>
              <td>{dadosPortabilidade.nomeCliente}</td>
            </tr>
            <tr>
              <td>Nome social</td>
              <td></td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{formatarCPF(dadosPortabilidade.cpf)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>{dadosPortabilidade.telefone}</td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">1.1 - Representante legal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome</td>
              <td></td>
            </tr>
            <tr>
              <td>Nome social</td>
              <td></td>
            </tr>
            <tr>
              <td>CPF</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">
                2 - IF credora original - Dados da operação a ser portada
                (informações fornecidas pelo solicitante (devedor))
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Razão social da IF credora original</td>
              <td>{dadosPortabilidade.instituicaoCredoraOriginal}</td>
            </tr>
            <tr>
              <td>CNPJ IF credora original</td>
              <td>{formatarCNPJ(dadosPortabilidade.cnpjCredoraOriginal)}</td>
            </tr>
            <tr>
              <td>Número do contrato</td>
              <td>{dadosPortabilidade.numeroContrato}</td>
            </tr>
            <tr>
              <td>Modalidade de crédito / Tipo de contrato</td>
              <td>{dadosPortabilidade.tipoProposta}</td>
            </tr>
            <tr>
              <td>Saldo devedor</td>
              <td>
                {formatarMoeda(
                  parseFloat(dadosPortabilidade.valorSaldoDevedor)
                )}
              </td>
            </tr>
            <tr>
              <td>Quantidade de parcelas remanescentes</td>
              <td>{dadosPortabilidade.parcelasEmAberto}</td>
            </tr>
          </tbody>
        </table>

        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">3 - Dados do responsável pelo atendimento</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Razão social</td>
              <td></td>
            </tr>
            <tr>
              <td>CNPJ</td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">
                3.1 - Dados do agente de crédito (se correspondente)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome completo</td>
              <td>{dadosPortabilidade.razaoSocialCorrespondente}</td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{dadosPortabilidade.cnpjCorrespondente}</td>
            </tr>
          </tbody>
        </table>

        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">
                4 - IF Proponente - SIMULAÇÃO das novas Condições Financeiras e
                CET para Requisição da Portabilidade de Operação de Crédito
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Razão social da IF proponente</td>
              <td>{dadosPortabilidade.nomeBanco}</td>
            </tr>
            <tr>
              <td>CNPJ proponente</td>
              <td>{formatarCNPJ(dadosPortabilidade.cnpjBanco)}</td>
            </tr>
            <tr>
              <td>Endereço da IF proponente</td>
              <td>{dadosPortabilidade.enderecoBancoCompleto.split(",")[0]}</td>
            </tr>
            <tr>
              <td>Número</td>
              <td>{dadosPortabilidade.enderecoBancoCompleto.split(",")[1]}</td>
            </tr>
            <tr>
              <td>Complemento</td>
              <td></td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>{dadosPortabilidade.enderecoBancoCompleto.split(",")[2]}</td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>{dadosPortabilidade.enderecoBancoCompleto.split(",")[3]}</td>
            </tr>
            <tr>
              <td>Estado</td>
              <td>{dadosPortabilidade.enderecoBancoCompleto.split(",")[4]}</td>
            </tr>
            <tr>
              <td>CEP</td>
              <td>
                {formatarCep(
                  dadosPortabilidade.enderecoBancoCompleto.split(",")[5]
                )}
              </td>
            </tr>
            <tr>
              <td>Data da simulação</td>
              <td>{formatarStringData(dadosPortabilidade.dataProposta)}</td>
            </tr>
            <tr>
              <td>Data do vencimento da primeira parcela</td>
              <td>{formatarStringData(dadosPortabilidade.vencimentoParcela)}</td>
            </tr>
            <tr>
              <td>Data de vencimento da última parcela</td>
              <td>{formatarStringData(dadosPortabilidade.ultimoVencimento)}</td>
            </tr>
            <tr>
              <td>Saldo devedor</td>
              <td>
                {formatarMoeda(
                  parseFloat(dadosPortabilidade.valorSaldoDevedor)
                )}
              </td>
            </tr>
            <tr>
              <td>Máximo de cobertura (cartão/cheque especial)</td>
              <td></td>
            </tr>
            <tr>
              <td>Valor da parcela</td>
              <td>{formatarMoeda(dadosPortabilidade.valorPrestacao)}</td>
            </tr>
            <tr>
              <td>Quantidade de parcelas</td>
              <td>{dadosPortabilidade.prazo}</td>
            </tr>
            <tr>
              <td>Taxa juros efetiva % a.m. (exceto imobiliário)</td>
              <td>
                {formatarPercentual(dadosPortabilidade.taxaJurosEfetivaAoMes)}
              </td>
            </tr>
            <tr>
              <td>Taxa juros efetiva % a.a.</td>
              <td>
                {formatarPercentual(dadosPortabilidade.taxaJurosEfetivaAoAno)}
              </td>
            </tr>
            <tr>
              <td>Taxa juros nominal % a.a.</td>
              <td>
                {formatarPercentual(dadosPortabilidade.taxaJurosNominalAoAno)}
              </td>
            </tr>
            <tr>
              <td>CET - Custo efetivo total % a.a.</td>
              <td>{formatarPercentual(dadosPortabilidade.cetAoAno)}</td>
            </tr>
            <tr>
              <td>Índice de preço ou base de remuneração (imobiliário)</td>
              <td></td>
            </tr>
            <tr>
              <td>Sistema de pagamento (imobiliário)</td>
              <td></td>
            </tr>
            <tr>
              <td>
                Condições do Termo de Solicitação de Portabilidade (alternativo)
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  );
}
