import { FaceTecSDK } from '../../../../../public/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { ZoomGlobalState } from '../../../../../public/core-sdk/ZoomGlobalState';
export default function PhotoIDMatchProcessor(
  sessionToken,
  bemFacetec,
  tokenSignatario,
) {
  var _this = this;
  this.latestNetworkRequest = new XMLHttpRequest();

  this.onFaceTecSDKCompletelyDone = function () {
    if (!_this.success) {
      _this.bemFacetec.clearLatestEnrollmentIdentifier();
    }

    if (parseInt(window.localStorage.getItem('tentativasLiveness')) === 0 ||
      (_this.quantidadeTentativasInscricao <=
        parseInt(window.localStorage.getItem('tentativasLiveness')) &&
        _this.sucessoInscricao)
    ) {
      _this.bemFacetec.onComplete(
        _this.latestSessionResult,
        _this.latestIDScanResult,
        _this.latestNetworkRequest.status,
      );
    }
  };

  this.isSuccess = function () {
    return _this.success;
  };

  this.success = false;
  this.bemFacetec = bemFacetec;
  this.latestSessionResult = null;
  this.latestIDScanResult = null;
  this.quantidadeTentativasFrente = 0;
  this.quantidadeTentativasVerso = 0;
  this.quantidadeTentativasInscricao = 0;
  this.tokenSignatario =
    tokenSignatario ||
    window.localStorage.getItem('url-token').replace('/b/', '');
  this.requisicaoVerso = false;
  this.sucessoInscricao = true;

  FaceTecSDK.FaceTecCustomization.setIDScanUploadMessageOverrides(
    'Enviando<br/>documento<br/>criptografado', // Upload of ID front-side has started.
    'Ainda enviando...<br/>Conexão lenta', // Upload of ID front-side is still uploading to Server after an extended period of time.
    'Envio concluído', // Upload of ID front-side to the Server is complete.
    'Processando documento', // Upload of ID front-side is complete and we are waiting for the Server to finish processing and respond.
    'Enviando<br/>documento<br/>criptografado', // Upload of ID back-side has started.
    'Ainda enviando...<br/>Conexão lenta', // Upload of ID back-side is still uploading to Server after an extended period of time.
    'Envio concluído', // Upload of ID back-side to Server is complete.
    'Processando documento', // Upload of ID back-side is complete and we are waiting for the Server to finish processing and respond.
    'Enviando<br/>informação confirmada', // Upload of User Confirmed Info has started.
    'Ainda enviando...<br/>Conexão lenta', // Upload of User Confirmed Info is still uploading to Server after an extended period of time.
    'Envio concluído', // Upload of User Confirmed Info to the Server is complete.
    'Processando', // Upload of User Confirmed Info is complete and we are waiting for the Server to finish processing and respond.
  );

  new FaceTecSDK.FaceTecSession(this, sessionToken);
}

PhotoIDMatchProcessor.prototype.processSessionResultWhileFaceTecSDKWaits =
  function (sessionResult, faceScanResultCallback) {
    var _this = this;
    this.latestSessionResult = sessionResult;

    this.bemFacetec.setLatestSessionResult(sessionResult);

    if (
      sessionResult.status !==
      FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
    ) {
      console.log(
        'Session was not completed successfully, cancelling.  Session Status: ' +
        FaceTecSDK.FaceTecSessionStatus[sessionResult.status],
      );
      this.latestNetworkRequest.abort();
      faceScanResultCallback.cancel();
      return;
    }

    var parameters = {
      faceScan: sessionResult.faceScan,
      auditTrailImage: sessionResult.auditTrail[0],
      lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
      sessionId: sessionResult.sessionId,
      externalDatabaseRefID: this.bemFacetec.getLatestEnrollmentIdentifier(),
    };

    this.latestNetworkRequest = new XMLHttpRequest();
    this.latestNetworkRequest.open(
      'POST',
      `${ZoomGlobalState.ZoomServerBaseURL}/ObterInscricao/${_this.tokenSignatario}`,
    );
    this.latestNetworkRequest.setRequestHeader(
      'Authorization',
      `Bearer ${window.localStorage.getItem('autorizacao')}`,
    );
    this.latestNetworkRequest.setRequestHeader(
      'Content-Type',
      'application/json',
    );
    this.latestNetworkRequest.setRequestHeader(
      'X-Device-Key',
      ZoomGlobalState.DeviceLicenseKeyIdentifier,
    );
    this.latestNetworkRequest.setRequestHeader(
      'X-User-Agent',
      FaceTecSDK.createFaceTecAPIUserAgentString(sessionResult.sessionId),
    );
    this.latestNetworkRequest.onreadystatechange = function () {
      if (_this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
        try {
          var responseJSON = JSON.parse(
            _this.latestNetworkRequest.responseText,
          ).retorno;

          _this.bemFacetec.setLatestServerResult(responseJSON);

          _this.quantidadeTentativasInscricao =
            _this.quantidadeTentativasInscricao + 1;

          var scanResultBlob = responseJSON.scanResultBlob;

          if (parseInt(window.localStorage.getItem('tentativasLiveness')) > 0 &&
            !responseJSON.success &&
            _this.quantidadeTentativasInscricao >=
            parseInt(window.localStorage.getItem('tentativasLiveness'))
          ) {
            _this.sucessoInscricao = false;
            faceScanResultCallback.cancel();
          } else if (responseJSON.wasProcessed === true) {
            FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage(
              'Verificação de selfie\r\nrealizada',
            );
            faceScanResultCallback.proceedToNextStep(scanResultBlob);
          } else {
            console.log('Unexpected API response, cancelling out.');
            faceScanResultCallback.cancel();
          }
        } catch (_a) {
          console.log('Exception while handling API response, cancelling out.');
          faceScanResultCallback.cancel();
        }
      }
    };
    this.latestNetworkRequest.onerror = function () {
      console.log('XHR error, cancelling.');
      faceScanResultCallback.cancel();
    };
    this.latestNetworkRequest.upload.onprogress = function (event) {
      var progress = event.loaded / event.total;
      faceScanResultCallback.uploadProgress(progress);
    };
    var jsonStringToUpload = JSON.stringify(parameters);
    this.latestNetworkRequest.send("'" + jsonStringToUpload + "'");
    window.setTimeout(function () {
      if (_this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
        return;
      }
      faceScanResultCallback.uploadMessageOverride('Ainda enviando...');
    }, 6000);
  };
PhotoIDMatchProcessor.prototype.processIDScanResultWhileFaceTecSDKWaits =
  function (idScanResult, idScanResultCallback) {
    var _this = this;
    this.latestIDScanResult = idScanResult;

    this.bemFacetec.setLatestIDScanResult(idScanResult);

    if (idScanResult.status !== FaceTecSDK.FaceTecIDScanStatus.Success) {
      console.log('ID Scan was not completed successfully, cancelling.');
      this.latestNetworkRequest.abort();
      this.latestNetworkRequest = new XMLHttpRequest();
      idScanResultCallback.cancel();
      return;
    }

    var MinMatchLevel = Number(window.localStorage.getItem('facematchMinimo'));

    var parameters = {
      idScan: idScanResult.idScan,
      sessionId: idScanResult.sessionId,
      externalDatabaseRefID: this.bemFacetec.getLatestEnrollmentIdentifier(),
      minMatchLevel: MinMatchLevel,
    };

    if (idScanResult.frontImages && idScanResult.frontImages[0]) {
      parameters.idScanFrontImage = idScanResult.frontImages[0];
    }
    if (idScanResult.backImages && idScanResult.backImages[0]) {
      _this.requisicaoVerso = true;
      parameters.idScanBackImage = idScanResult.backImages[0];
    }

    this.latestNetworkRequest = new XMLHttpRequest();
    this.latestNetworkRequest.open(
      'POST',
      `${ZoomGlobalState.ZoomServerBaseURL}/ObterLivenessFacematch/${_this.tokenSignatario}`,
    );
    this.latestNetworkRequest.setRequestHeader(
      'Authorization',
      `Bearer ${window.localStorage.getItem('autorizacao')}`,
    );
    this.latestNetworkRequest.setRequestHeader(
      'Content-Type',
      'application/json',
    );
    this.latestNetworkRequest.setRequestHeader(
      'X-Device-Key',
      ZoomGlobalState.DeviceLicenseKeyIdentifier,
    );
    this.latestNetworkRequest.setRequestHeader(
      'X-User-Agent',
      FaceTecSDK.createFaceTecAPIUserAgentString(idScanResult.sessionId),
    );
    this.latestNetworkRequest.onreadystatechange = function () {
      if (_this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
        try {
          var responseJSON = JSON.parse(
            _this.latestNetworkRequest.responseText,
          ).retorno;

          if (_this.requisicaoVerso) {
            _this.quantidadeTentativasVerso = _this.quantidadeTentativasVerso + 1;
          } else {
            _this.quantidadeTentativasFrente = _this.quantidadeTentativasFrente + 1;
          }

          var quantidadeTentativas = _this.requisicaoVerso ? _this.quantidadeTentativasVerso : _this.quantidadeTentativasFrente;

          var tentativasFacematch = parseInt(window.localStorage.getItem('tentativasFacematch'));

          var existeQuantidadeTentativas = tentativasFacematch > 0;

          var limiteTentativaAtingido = existeQuantidadeTentativas && quantidadeTentativas >= tentativasFacematch;

          var criterioSucesso = _this.requisicaoVerso ? responseJSON.isCompletelyDone : responseJSON.matchLevel >= MinMatchLevel;

          var scanResultBlob = responseJSON.scanResultBlob;

          window.localStorage.setItem('matchLevel', responseJSON.matchLevel || 0);

          if (limiteTentativaAtingido && !criterioSucesso) {
            _this.success = true;
            idScanResultCallback.cancel();
          } else if (responseJSON.wasProcessed === true) {
            _this.success = true;
            FaceTecSDK.FaceTecCustomization.setIDScanResultScreenMessageOverrides(
              'Documento capturado', // Successful scan of ID front-side (ID Types with no back-side).
              'Frente capturada', // Successful scan of ID front-side (ID Types that do have a back-side).
              'Verso capturado', // Successful scan of the ID back-side.
              "Passaporte capturado", // Successful scan of a Passport
              'Verificação realizada', // Successful upload of final IDScan containing User-Confirmed ID Text.
              "Documento capturado", // Successful upload of final Photo ID Scan result but scan is flagged as requiring additional review.
              'Falha na verificação', // Case where a Retry is needed because the Face on the Photo ID did not Match the User's Face highly enough.
              'Documento<br/>não está visível', // Case where a Retry is needed because a Full ID was not detected with high enough confidence.
              'Documento<br/>não está legível', // Case where a Retry is needed because the OCR did not produce good enough results and the User should Retry with a better capture.
              "Falha na verificação" // Case where there is likely no OCR Template installed for the document the User is attempting to scan.
            );
            idScanResultCallback.proceedToNextStep(scanResultBlob);
          } else {
            idScanResultCallback.cancel();
          }
        } catch (_a) {
          console.log('Exception while handling API response, cancelling out.');
          idScanResultCallback.cancel();
        }
      }
    };
    this.latestNetworkRequest.onerror = function () {
      console.log('XHR error, cancelling.');
      idScanResultCallback.cancel();
    };

    this.latestNetworkRequest.upload.onprogress = function (event) {
      var progress = event.loaded / event.total;
      idScanResultCallback.uploadProgress(progress);
    };

    var jsonStringToUpload = JSON.stringify(parameters);
    this.latestNetworkRequest.send("'" + jsonStringToUpload + "'");
  };
